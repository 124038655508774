<div class="position-relative">
  <div class="background"></div>
  <div class="container profile-card">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-8 main-heading">View Profile</div>
      <div class="col-lg-4 col-md-4 col-sm-4 close">
        <button type="button" class="close" (click)="onClose()" aria-label="Close">
          <span aria-hidden="true"><i class="material-icons">close</i></span>
        </button>
      </div>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <img *ngIf="this.empImage != null" class="img-fluid mx-auto" id="profile-image" [src]="empImage" />
    </div>
    <div class="row mt-2">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
        <span class="text-center name">{{ this.empName }}</span>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center mb-2">
        <span class="text-center role">
          {{ this.empDesignation }}
        </span>
      </div>
    </div>
    <div class="row stats py-3">
      <div class="col-lg-6 col-xl-3 col-md-6">
        <div class="d-flex">
          <div class="d-flex">
            <img class="img-property" src="assets/images/Lb_icon.svg" alt="point earned" />
          </div>
          <div>
            <div class="title">{{ empPos }}</div>
            <div class="sub-title">Leaderboard Rank</div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-xl-3 col-md-6">
        <div class="d-flex">
          <div class="d-flex">
            <img class="img-property" src="assets/images/point earned.svg" alt="point earned" />
          </div>
          <div>
            <div class="title">{{ availablePoints }}</div>
            <div class="sub-title">Points Earned</div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-xl-3 col-md-6">
        <div class="d-flex">
          <div class="d-flex">
            <img class="img-property" src="assets/images/wallet-icon.svg" alt="point earned" />
          </div>
          <div>
            <div class="title">₹ {{ totalBudget }}</div>
            <div class="sub-title">Wallet Amount</div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-xl-3 col-md-6">
        <div class="d-flex">
          <div class="d-flex">
            <img class="img-property" src="assets/images/redeem icon.svg" alt="point earned" />
          </div>
          <div>
            <div class="title">₹ {{ redeemedReward }}</div>
            <div class="sub-title">Amount Redeemed</div>
          </div>
        </div>
      </div>
    </div>

    <div class="award-colm">
      <div class="award-recieved p-1">
        <div class="d-flex justify-content-between">
          <span>Awards Received</span>
          <div class="d-flex justify-content-end mr-4" *ngIf="awards.length > 2">
            <img class="pointer" (click)="prevSlide()" src="assets/images/left.svg" alt="left icon" />
            <img class="ml-2 pointer" (click)="nextSlide()" src="assets/images/right.svg" alt="right icon" />
          </div>
        </div>
      </div>

      <div *ngIf="!awards.length" class="mt-5">
        <div class="d-flex justify-content-center">
          <img src="assets/images/no_award.svg" alt="" />
        </div>
        <div class="">
          <p class="no-awards-head">No Awards Received Yet</p>
          <p class="no-awards-subhead">
            Your hard work will shine soon. Keep striving for excellence!
          </p>
        </div>
      </div>

      <div *ngIf="this.awards.length > 2; else second">
        <ngb-carousel #carousel class="mt-1" id="carousel-properties">
          <ng-template ngbSlide *ngFor="let award of groupedAwards">
            <div class="row p-3">
              <div class="col-lg-6 col-xl-6 col-md-6" *ngFor="let awardDetails of award">
                <ng-container *ngTemplateOutlet="
                  awardCard;
                  context: { $implicit: awardDetails }
                "></ng-container>
              </div>
            </div>
          </ng-template>
        </ngb-carousel>
      </div>

      <ng-template #second>
        <div class="row p-3">
          <div class="col-lg-6 col-xl-6 col-md-6" *ngFor="let awardDetails of awards">
            <ng-container *ngTemplateOutlet="awardCard; context: { $implicit: awardDetails }"></ng-container>
          </div>
        </div>
      </ng-template>
    </div>
  </div>

  <ng-template #awardCard let-awardDetails>
    <mat-card>
      <div class="card-bg d-flex justify-content-center">
        <img class="img-height" [src]="awardDetails?.s3Image" alt="award icon" />
      </div>
      <div class="text-center award-text">
        {{ awardDetails?.employeeAnalytics?.typeOfAward }}
      </div>
      <div class="d-flex justify-content-between mt-3">
        <div class="d-flex">
          <div>
            <img src="assets/images/employee-icon.svg" alt="" />
          </div>
          <div>
            <span class="emp-name">{{
              awardDetails?.employeeAnalytics?.employeeName
              }}</span>
            <p class="emp-designation" [matTooltip]="awardDetails?.employeeAnalytics?.employeeDesignation">
              {{ awardDetails?.employeeAnalytics?.employeeDesignation }}
            </p>
          </div>
        </div>
        <div class="wid">
          <img style="width: 40px; height: 40px" src="assets/images/calendar.svg" alt="calendar icon" />
          <div class="mt-2">
            <span class="emp-name">{{
              awardDetails.recommendation?.quarter
              }}</span>
          </div>
        </div>
      </div>

      <div class="d-flex">
        <p class="reason">Reason For Nomination</p>
        <div class="ml-2">
          <img class="arrow pointer" [class.rotated]="awardDetails?.expand"
            (click)="isExpand(awardDetails?.recommendation?.id)" src="assets/images/arrow_down.svg" alt="arrow down" />
        </div>
      </div>
      <div class="content reason-text" [class.visible]="awardDetails.expand">
        {{ awardDetails?.recommendation?.praise }}
      </div>

      <div class="certi certi-text pointer" (click)="downloadCertificate(awardDetails?.certificate)">
        <span class="certificate-text">Download Certificate</span>
      </div>
    </mat-card>
  </ng-template>
</div>