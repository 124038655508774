<form [formGroup]="modalForm" class="p-2">
    <div class="modal-header position-sticky-top">
      <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">
        <p class="modal-header-text modal-title">Delegate contributions</p>
      </div>
      <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
        <button type="button" class="info" [matMenuTriggerFor]="delegatecontribution">
          <img src="../../../../assets/images/info_.png" />
        </button>
      </div>
      <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
        <button type="button" class="close" (click)="onClose()" aria-label="Close">
          <span aria-hidden="true"><i class="material-icons">close</i></span>
        </button>
      </div>
    </div>
  
    <mat-menu #delegatecontribution="matMenu" xPosition="before" class="menu-styling">
  
      <ul class="ul-container">
        <div class="d-flex flex-nowrap bd-highlight ">
          <div class="p-2 bd-highlight widthOne">
            <p class="heading">Select User</p>
          </div>
          <div class="p-2 bd-highlight widthTwo">
            <p class="arrow">-</p>
          </div>
          <div class="p-2 bd-highlight widthThree">
            <p class="heading-data">Select the colleague to whom you want to delegate this contribution.</p>
          </div>
        </div>
      </ul>
    </mat-menu>
  
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <mat-form-field class="w-100 " appearance="fill">
        <mat-label class="label-text">Delegate to*</mat-label>
        <mat-select formControlName="selectedDelegatedEmployee" placeholder="Select User*" #singleSelect disableOptionCentering="panel-class" >
          <mat-option >
            <ngx-mat-select-search placeholderLabel="Search user*" noEntriesFoundLabel="No user found"
              [formControl]="empListFilterCtrl"></ngx-mat-select-search>
          </mat-option>
  
          <mat-option *ngFor="let employee of filteredEmployeesList | async" [value]="employee">
            {{employee.id}} {{employee.name}}
          </mat-option>
        </mat-select>
        <mat-error class="error-style" *ngIf="modalForm.controls.selectedDelegatedEmployee?.errors && this.submitted">
          Select user to delegate the contribution
        </mat-error>
      </mat-form-field>
    </div>
  
    <div class="form-buttons row ml-1 mt-3">
      <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
        <button type="submit" id="next_btn" (click)="onSubmit()">SUBMIT
        </button>
      </div>
      <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
        <button mat-raised-button id="cancel_btn" (click)="onClose()">CANCEL</button>
      </div>
    </div>
  </form>