
<button mat-icon-button class="close-button">
    <img
      class="close-icon cut"
      src="assets/images/Cut.svg"
      (click)="onClose()"
      alt="close icon"
    />
  </button>
  <mat-card class="leaderboard d-flex justify-content-start mb-4">
    <img
      class="project-heading"
      src="assets/images/projectView.svg "
      alt="banner"
    />
    <div class="ml-1 mt-2 pl-3">
      <span class="heading">View Project</span>
    </div>
  </mat-card>
  
  <div class="container ml-3 mx-height ">
  
    <div class="row mt-4">
      <div class="row mb-3" [ngClass]="{ mrgbtm: mobile }">
        <div
          class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
        >  
          <div>
            <p class="title">{{viewDetail.title}}</p>
            <p class="subtitle">{{ viewDetail.description}}</p>
          </div>
        </div>
      </div>

      <div class="row mb-3" [ngClass]="{ mrgbtm: mobile }">
        <div
        class="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-start"
      >
        <img
          class="mr-3 img-height"
          src="assets/images/Date.svg"
          alt="date-icon"
        />

        <div>
          <p class="title">Created On</p>
          <p class="subtitle">{{ viewDetail.date }}</p>
          <p></p>
        </div>
      </div>
      <div
      class="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-start"
    >
      <img
        class="mr-3 img-height"
        src="assets/images/Client Name.svg"
        alt="client name icon"
      />

      <div>
        <p class="title">Client Name</p>
        <p class="subtitle">{{ viewDetail.client }}</p>
        <p></p>
      </div>
    </div>

      </div>

      <div class="row mb-3" [ngClass]="{ mrgbtm: mobile }">
        <div
        class="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-start"
      >
        <img
          class="mr-3 img-height"
          src="assets/images/Date.svg"
          alt="date icon"
        />

        <div>
          <p class="title">Start Date</p>
          <p class="subtitle">{{ viewDetail.contributionDate}}</p>
          <p></p>
        </div>
      </div>
      <div
      class="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-start"
    >
      <img
        class="mr-3 img-height"
        src="assets/images/Duration.svg"
        alt="duration icon"
      />

      <div>
        <p class="title">Project Duration</p>
        <p class="subtitle">{{ viewDetail.duration ? viewDetail.duration : "-"   }}</p>
        <p></p>
      </div>
    </div>

      </div>

      <div class="row mb-3" [ngClass]="{ mrgbtm: mobile }">
      
        <div
        class="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-start"
      >
        <img class="mr-3 img-height" src="assets/images/Project Type.svg" alt="project type" />

        <div>
          <p class="title">Project Type</p>
          <p class="subtitle">{{ viewDetail.type }}</p>
          <p></p>
        </div>
      </div>
        <div
          class="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-start"
        >
          <img class="mr-3 img-height" src="assets/images/goaltype.svg" alt="goal type" />
  
          <div>
            <p class="title">Goal Type</p>
            <p class="subtitle">{{ goalType }}</p>
            <p></p>
          </div>
        </div>
      </div>
  
    </div>
    <mat-divider></mat-divider>
    <div class="row mb-4 mt-4">
      <div
        class="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-start"
      >
        <img
          class="mr-3 img-height"
          src="assets/images/contributionPoint.svg  "
          alt="contribution point"
        />
  
        <div>
          <p class="title">{{reviewActivity?viewDetail.contributedBy+"'s":"Your"}} Contribution Points</p>
          <p class="subtitle">{{ viewDetail.points }}</p>
          <p></p>
        </div>
      </div>
      <div
        class="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-start"
      >
        <div>
          <p class="title">Status</p>
          <div
            div
            class="text-white card-status p-1"
            [ngClass]="{
              rejected: viewDetail.status === 'DECLINED',
              success: viewDetail.status === 'APPROVED',
              pending: viewDetail.status === 'PENDING APPROVAL',
              drafted: viewDetail.status === 'DRAFTED'
            }"
          >
            <ng-container [ngTemplateOutlet]="statusIcon"> </ng-container>
            {{ viewDetail.status }}
          </div>
        </div>
      </div>
    </div>
  
    <ng-template #statusIcon>
      <img
        class="icon"
        *ngIf="viewDetail.status === 'DRAFTED'"
        src="../../../../assets/images/n_image/draft-icon.svg"
      />
      <img
        class="icon"
        *ngIf="viewDetail.status === 'PENDING APPROVAL'"
        src="../../../../assets/images/n_image/pending-icon.svg"
      />
      <img
        class="icon"
        *ngIf="viewDetail.status === 'APPROVED'"
        src="../../../../assets/images/n_image/approved-icon.svg"
      />
      <img
        class="icon"
        *ngIf="viewDetail.status === 'DECLINED'"
        src="../../../../assets/images/n_image/declined-icon.svg"
      />
    </ng-template>

  <mat-divider *ngIf="reviewActivity"></mat-divider>

  <div class="row mb-4 mt-4" *ngIf="reviewActivity">
    <div class="col-lg-12 col-sm-12 col-xs-12 d-flex justify-content-start">
      <button (click)="approveActivity()"
        class="bulk_approve_btn mr-3">
        <div class="material-icons">
          done
        </div> Approve
      </button>
      <button (click)="rejectActivity()"
        class="bulk_reject_btn mr-3">
        <span class="material-icons">
          close
        </span>Reject
      </button>
      <button (click)="delegate()"  class="delegate_btn ">
        <img src="assets/images/delegate.png">
        <div class="dl">
          Delegate
        </div>
      </button>
    </div>
  </div>
  </div>
