import { AuthUserService } from 'src/app/services/auth-user.service';
import { Component, Inject, OnInit } from '@angular/core';
import { EmployeeDetails, ProfileData } from 'src/app/shared/models/profile.model';
import { ProjectDetails } from 'src/app/shared/models/project.model';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { ProjectService } from '../../project/service/project.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MyProfileService } from '../../dashboard/dash-info-cards/service/my-profile.service';
import { CommonService } from 'src/app/services/common.service';
import { CommentModalComponent } from 'src/app/shared/components/comment-modal/comment-modal.component';
import { ChangestatusService } from 'src/app/services/changestatus.service';
import { DelegateService } from '../../delegate-contributions/delegate-module/delegate.service';
import { genericDailogData } from 'src/app/shared/models/confirmation-modal.model';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';
import { DelegateModuleComponent } from '../../delegate-contributions/delegate-module/delegate-module.component';

@Component({
  selector: 'app-project-view',
  templateUrl: './project-view.component.html',
  styleUrls: ['./project-view.component.css']
})
export class ProjectViewComponent implements OnInit {
  empEmail : String;
  goalType?:string;
  mobile: boolean;
  projectID:number;
  empId:string;
  userData:EmployeeDetails;
  date:number;
  year:string;
  viewDetail:ProjectDetails;
  reviewActivity: boolean;
  dataDialog: genericDailogData;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: number | { id: number; reviewActivity: boolean },
    private _responsiveService: ResponsiveService,
    private projectViewService:ProjectService,
    private _authService:AuthUserService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ProjectViewComponent>,
    private profileService: MyProfileService,
    private commonService : CommonService,
    private _changeStatus: ChangestatusService,
    private delegateService: DelegateService
  ) {
    this.viewDetail={}as ProjectDetails;
    this.projectID=0;
    this.date=0;
    this.year='';
    this.mobile=false;
    this.empId='';
    this.userData={}as EmployeeDetails;
    this.empEmail = '';
    this.reviewActivity = false;
    this.dataDialog = { title: '', img: '', heading: '' };
    if (typeof data === 'number') {
      this.projectID = data;
    } else {
      this.projectID = data.id;
      this.reviewActivity = data.reviewActivity;
    }
   }

  ngOnInit(): void {
    this.commonService.setAdminPortalOpened(false);
    this._authService.getEmployeeDetailBehaviorSubject().subscribe((item:ProfileData) => {
      if (item?.data?.email) {
        this.empEmail = item.data.email;
      }
    });
    this._responsiveService.getMobileStatus().subscribe((isMobile:boolean) => {
      this.mobile = isMobile ? true : false;
    });
    this.onResize();
    this.getSelectedProjectView();
    if (this.empId != 'user') this.getUserData();
  }

  /**
   * Get project details
   */
  getSelectedProjectView():void {
       this.projectViewService.getSelectedProjectView(Number(this.projectID)).subscribe(data=>{
      this.date = (Number)(data.data.createdOn.substr(0,4));
      this.year = this.date+'-'+(this.date+1);
      this.viewDetail.description=data.data.description,
      this.viewDetail.title=data.data.summary,
      this.viewDetail.client=data.data.project.clientName,
      this.viewDetail.duration=data.data.project.duration,
      this.viewDetail.type=data.data.project.projectType,
      this.viewDetail.goalType= data.data.ecdc, 
       this.viewDetail.status=data.data.status,
       this.viewDetail.points=data.data.totalPoints,
       this.viewDetail.date=data.data.createdOn.substr(0,10),
       this.viewDetail.category=data.data.contributionCategory.name
       this.viewDetail.contributedBy=data.data.createdBy,
       this.viewDetail.comment=data.data.comments;
       this.viewDetail.contributionDate = data.data.contributionDate.substr(0,10);
       this.goalType = (this.viewDetail.goalType === 'EC') ? 'Engineering Council (EC)' : 
       (this.viewDetail.goalType === 'DC') ? 'Delivery Council (DC)' : '';

    })
  }

  /**
   * Get the profile image
   */
  getUserData(): void {
    this.profileService.getProfileDetailBehaviorSubject().subscribe((data:ProfileData) => {
      if (data?.data) {
        this.userData = data.data;
        this.userData.imgAsset = this.userData.image ? atob(this.userData.image) : 'assets/images/def.png';
      }
    });
  }

  /**
   * Check screen width
   */
  onResize(): void {
    this._responsiveService.checkWidth();
  }

  /**
   * This function will close the modal
   */
  onClose(): void {
    this.dialogRef.close();
  }

  approveActivity(): void {
    this.commonService.approveActivity(this.projectID, this.empEmail, this.dialogRef);
  }

  rejectActivity(): void {
    this.commonService.rejectActivity(this.projectID, this.empEmail, this.dialogRef);
  }

  delegate() {
    this.commonService.delegate(this.projectID, this.empEmail, this.dialogRef, this.empId);
  }
}
