import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DelegateService {

  constructor(private http: HttpClient) {}

  delegate(delegateReqBody: any) : Observable<any>{
    return this.http.post<any>('contripoint/contributions/delegate', delegateReqBody);
  }
  
  employeedata(empEmail:string , contributionId : Number[]){
    let obj ={
      'empEmail' : empEmail,
      'contributionId' : contributionId
    }
    return this.http.post<any>('contripoint/fetch/excluding/employee/dropdown',obj);
}

  getDelegateYearsList(empEmail: String): Observable<any> {
    const obj = {
      empEmail: empEmail,
    };
    return this.http.post<any>('contripoint/fetch/delegate-years', obj);
  }

  getReviewYearsList(empEmail: String): Observable<any> {
    const obj = {
      empEmail: empEmail,
    };
    return this.http.post<any>('contripoint/fetch/review-years', obj);
  }
}
