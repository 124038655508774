import { takeUntil } from 'rxjs/operators';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject, Subject } from 'rxjs';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { DelegateService } from './delegate.service';
interface empList {
  id: string;
  name: string;
}

@Component({
  selector: 'app-delegate-module',
  templateUrl: './delegate-module.component.html',
  styleUrls: ['./delegate-module.component.css']
})
export class DelegateModuleComponent implements OnInit {
  empListFilterCtrl!: UntypedFormControl;
  modalForm!: UntypedFormGroup;
  submitted: boolean = false;
  empEmail : string;
  _employeeList = [
    { id: ``, name: `` }
  ];

  public filteredEmployeesList: ReplaySubject<empList[]> = new ReplaySubject<empList[]>(1);
  protected _onDestroy = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Number[],
    public dialogRef: MatDialogRef<DelegateModuleComponent>,
    private delegateService:DelegateService,
    private _authService: AuthUserService,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.empListFilterCtrl = new UntypedFormControl();
    this.empEmail = ``;
  }


  ngOnInit(): void {
    this.modalForm = this.formBuilder.group({
      selectedDelegatedEmployee: [``, Validators.required],
    });
    this._authService.getEmployeeDetailBehaviorSubject().subscribe(item => {
      if (item?.data?.email) {
        this.empEmail = item.data.email
      }
    });
    this.employeedropdown();
  }
/**
 * get employee list from employee directory.
 */
  employeedropdown = (): void => {
    this.delegateService.employeedata(this.empEmail,this.data).subscribe(data => {
      if (data?.data) {
        this._employeeList = data.data;
        this.dropDown();
      }
    })
  }
  /**
   * get employee list from employee directory.
   */
  dropDown = (): void =>{
    this.filteredEmployeesList.next(this._employeeList.slice());
    this.empListFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filteremployeeList();
      });
  }
  /**
   * Filter Employee list
   */
  filteremployeeList = ()=> {
    if (!this._employeeList) {
      return;
    }
    let search = this.empListFilterCtrl.value;
    if (!search) {
      this.filteredEmployeesList.next(this._employeeList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredEmployeesList.next(
      this._employeeList.filter(employee => employee.name.toLowerCase().indexOf(search) > -1)
    );
  }
/**
 * Send delegated employee name to se
 * @returns selected employee name
 */
  onSubmit = ()=> {
    this.submitted = true;
    if (this.modalForm.invalid)
      return;
    this.dialogRef.close({ resultForm: this.modalForm.value.selectedDelegatedEmployee});
  }
  /**
   * close the delegate option.
   */
  onClose= ()=> {
    this.dialogRef.close();
  }
}