import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { CommonService } from 'src/app/services/common.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { dailogData, genericDailogData } from 'src/app/shared/models/confirmation-modal.model';
import {
  EmployeeDetails,
  ProfileData,
} from 'src/app/shared/models/profile.model';
import { SessionDetails } from 'src/app/shared/models/training-session.model';
import { MyProfileService } from '../../dashboard/dash-info-cards/service/my-profile.service';
import { TrainingandsessionsService } from '../service/trainingandsessions.service';

@Component({
  selector: 'app-training-session-view',
  templateUrl: './training-session-view.component.html',
  styleUrls: ['./training-session-view.component.css'],
})
export class TrainingSessionViewComponent implements OnInit {
  employeeId: string;
  empEmail: String;
  mobile: boolean;
  trainingID: number;
  checkApprove: String;
  dataDialog: genericDailogData;
  viewType: String;
  empId: String;
  goalType?: string;
  date: number;
  year: String;
  userData: EmployeeDetails;
  viewDetail: SessionDetails;
  reviewActivity: boolean;

  constructor(
    private route: ActivatedRoute,
    private _responsiveService: ResponsiveService,
    @Inject(MAT_DIALOG_DATA) public data: number | { id: number; reviewActivity: boolean },
    private trainingViewService: TrainingandsessionsService,
    private _authService: AuthUserService,
    public dialog: MatDialog,
    private _router: Router,
    public dialogRef: MatDialogRef<TrainingSessionViewComponent>,
    private profileService: MyProfileService,
    private commonService: CommonService
  ) {
    this.employeeId = '';
    this.trainingID = 0;
    this.checkApprove = '';
    this.empId = '';
    this.date = 0;
    this.year = '';
    this.viewType = '';
    this.userData = {} as EmployeeDetails;
    this.viewDetail = {} as SessionDetails;
    this.mobile = false;
    this.empEmail = '';
    this.reviewActivity = false;
    this.dataDialog = { title: '', img: '', heading: '' };
    this.checkApprove = '';
    if (typeof data === 'number') {
      this.trainingID = data;
    } else {
      this.trainingID = data.id;
      this.reviewActivity = data.reviewActivity;
    }
  }

  ngOnInit(): void {
    this.commonService.setAdminPortalOpened(false);
    this._authService.getEmployeeDetailBehaviorSubject().subscribe((item) => {
      if (item) {
        this.empEmail = item.data.email;
      }
    });
    this.getSelectedFeedbackView();
    this.onResize();
    this._responsiveService.getMobileStatus().subscribe((isMobile: boolean) => {
      this.mobile = !!isMobile;
    });

    if (this.empId != 'user') this.getUserData();
  }

  /**
   * Check screen width
   */
  onResize(): void {
    this._responsiveService.checkWidth();
  }

  /**
   * Get project details
   */
  getSelectedFeedbackView(): void {
    this.trainingViewService
      .getSelectedTrainingSessionView(Number(this.trainingID))
      .subscribe((data) => {
        let categoryType = '';
        categoryType = data.data.contributionCategory.name
          .match(/[A-Z][a-z]+|[0-9]+/g)
          .join(' ');
        let technologyarray = '';
        for (
          let i = 0;
          i < data.data.trainingAndSession.technology.length;
          i++
        ) {
          technologyarray +=
            data.data.trainingAndSession.technology[i].employeeTechnology +
            ' , ';
        }
        let b = technologyarray.replace(/,\s*$/, '');
        this.viewDetail = data?.data;
        (this.viewDetail.technologymentored = b),
          (this.date = Number(data.data.createdOn.substr(0, 4)));
        this.year = this.date + '-' + (this.date + 1);
        this.viewDetail.endDate = data?.data?.trainingAndSession?.endDate;
        this.viewDetail.startDate = data?.data?.trainingAndSession?.startDate;
        this.viewDetail.headcount = data?.data?.trainingAndSession?.headcount;
        this.viewDetail.startDate = data?.data?.createdOn;
        this.viewDetail.createdBy = data?.data?.createdBy;
      });
  }

  /**
   * Get status image
   */
  getStatusImage(data: String): String {
    if (data == 'PENDING APPROVAL') {
      return '../../../../assets/images/n_image/pendingBtn.svg';
    } else if (data == 'DECLINED') {
      return '../../../../assets/images/n_image/deleteBtn.svg';
    } else if (data == 'APPROVED') {
      return '../../../../assets/images/n_image/approveBtn.svg';
    } else {
      return '../../../../assets/images/n_image/draft-icon.svg';
    }
  }

  /**
   * Get profile image
   */
  getUserData(): void {
    this.profileService
      .getProfileDetailBehaviorSubject()
      .subscribe((data: ProfileData) => {
        if (data?.data) {
          this.userData = data.data;
          this.userData.imgAsset = this.userData.image
            ? atob(this.userData.image)
            : 'assets/images/def.png';
        }
      });
  }
  /**
   * This function will close the modal
   */
  onClose(): void {
    this.dialogRef.close();
  }

  approveActivity(): void {
    this.commonService.approveActivity(this.trainingID, this.empEmail, this.dialogRef);
  }

  rejectActivity(): void {
    this.commonService.rejectActivity(this.trainingID, this.empEmail, this.dialogRef);
  }

  delegate() {
    this.commonService.delegate(this.trainingID, this.empEmail, this.dialogRef, this.empId);
  }
}
