<div class="d-flex flex-column shadow-lg bg-light sidenav w-65" id="sideNav">
    <a (click)="openDialog()" class="mt-2 pointer">
        <img class="profile-img" [src]="userImage" alt="profile" height="48" width="48" 
            />
    </a>
    <ul class="nav nav-pills nav-flush flex-column text-center">
        <li>
            <a routerLink="/main-dashboard" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive #rlHome="routerLinkActive" matTooltip="Dashboard" matTooltipPosition="after"
                class="nav-link ">
                <img class="icon_on mt-1 ml-1" *ngIf="rlHome.isActive; else homeInactive "
                    ngSrc='assets/images/homewhite.svg' alt="HomeWhite" height="30" width="30" loading="eager"
                    fetchpriority="high" />
                <ng-template #homeInactive>
                    <img class="icon_on mt-1 ml-1" ngSrc="assets/images/HomeGray.svg" alt="HomeGrey" height="30"
                        width="30" loading="eager" fetchpriority="high">
                </ng-template>
                <img class="icon_off" ngSrc="assets/images/HomeGreen.svg" alt="HomeGreen" height="30" width="30"
                    loading="eager" fetchpriority="high" />
            </a>
        </li>
        <!-- <li >
          <a 
          routerLink="/event"
          [routerLinkActive]="['active']"
          [routerLinkActiveOptions]="{ exact: true }"
          routerLinkActive #rlEvent="routerLinkActive"
          matTooltip="Event"
          matTooltipPosition="after"
          class="nav-link ml-1"
          >
          <img class="icon_on" *ngIf="rlEvent.isActive; else eventInactive"
              ngSrc='assets/images/aEvent Icon.svg'  alt="EventWhite"  height="22" width="19" loading="eager"  fetchpriority="high" />
          <ng-template #eventInactive>
              <img class="icon_on" ngSrc="assets/images/n_event.svg" alt="EventGrey"   height="22" width="19" loading="eager"  fetchpriority="high" >
          </ng-template>
          <img
              class="icon_off"
              ngSrc="assets/images/n_image/Event Icon.svg" alt="EventGreen" height="22" width="19" loading="eager"  fetchpriority="high" 
              />
          </a>
      </li>
      <li >
          <a 
          routerLink="/rewards"
          [routerLinkActive]="['active']"
          routerLinkActive  #rlaReward="routerLinkActive"
          [routerLinkActiveOptions]="{ exact: true }"
          matTooltip="Reward"
          matTooltipPosition="after"
          class="nav-link "
          >
          <img  class="icon_on" *ngIf="rlaReward.isActive; else rewardInactive"
              ngSrc='assets/images/aReward Icon.svg' alt="RewardIcon"  height="24" width="24" loading="eager"  fetchpriority="high" />
          <ng-template #rewardInactive>
              <img  class="icon_on" ngSrc="assets/images/n_reward.svg" alt="RewardGrey"   height="24" width="24" loading="eager"  fetchpriority="high" >
          </ng-template>
          <img
              class="icon_off"
              ngSrc="assets/images/n_image/Reward Icon.svg" alt="RewardGreen"   height="24" width="24" loading="eager"  fetchpriority="high"
              />
          </a>
      </li> -->
        <li>
            <a routerLink="contribution-history/dashboard" [routerLinkActive]="['active']"
                [routerLinkActiveOptions]="{ exact: true }" routerLinkActive #rlaHistory="routerLinkActive"
                matTooltip="Contribution History" matTooltipPosition="after" class="nav-link ">
                <img class="icon_on" *ngIf="rlaHistory.isActive; else contributionHistoryInactive"
                    ngSrc='assets/images/aContribution History.svg' alt="Contribution History White" height="24"
                    width="28" loading="eager" fetchpriority="high" />
                <ng-template #contributionHistoryInactive>
                    <img class="icon_on" ngSrc='assets/images/n_image/Contribution.svg' alt="Contribution History Grey"
                        height="24" width="28" loading="eager" fetchpriority="high">
                </ng-template>
                <img class="icon_off" ngSrc="assets/images/n_image/Contribution Icon.svg"
                    alt="Contribution History Green" height="24" width="28" loading="eager" fetchpriority="high" />
            </a>
        </li>
        <li (click)="contributionType('delegate')">
            <a routerLink="/delegate-contributions" [routerLinkActive]="['active']"
                [routerLinkActiveOptions]="{ exact: false }" routerLinkActive #rlaDelegate="routerLinkActive"
                matTooltip="Delegate Contributions" matTooltipPosition="after" class="nav-link ml-1">
                <img class="icon_on" *ngIf="rlaDelegate.isActive; else delegateInactive"
                    ngSrc='assets/images/DelegateWhite.svg' alt="Delegate White" height="15" width="24" loading="eager"
                    fetchpriority="high" />
                <ng-template #delegateInactive>
                    <img class="icon_on mt-2" ngSrc='assets/images/n_Delegate.svg' alt="Delegate Grey" height="15"
                        width="24" loading="eager" fetchpriority="high">
                </ng-template>
                <img class="icon_off mt-2" ngSrc="assets/images/Delegategreen.svg" alt="Delegate Green" height="15"
                    width="24" loading="eager" fetchpriority="high" />
            </a>
        </li>
        <a class="nav-link" *ngIf="checkManager || isAdmin || coChairFlow || managerFlow">
            <img src="../../assets/images/n_vline.svg" />
        </a>


        <li *ngIf="isAdmin || checkManager || isHr || transactionAccess">
            <a routerLink="/admin-portal" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive #rlAdmin="routerLinkActive" matTooltip="Admin Portal" matTooltipPosition="after"
                class="nav-link ml-1">
                <img class="icon_on" *ngIf="rlAdmin.isActive; else adminInactive"
                    ngSrc='assets/images/aAdmin Portal.svg' alt="admin white" height="22" width="20" loading="eager"
                    fetchpriority="high" />
                <ng-template #adminInactive>
                    <img class="icon_on" ngSrc='assets/images/n_Adminportal.svg' alt="Admin grey" height="22" width="20"
                        loading="eager" fetchpriority="high">
                </ng-template>
                <img class="icon_off" ngSrc="assets/images/n_image/Admin Icon.svg" alt="admin green" height="22"
                    width="20" loading="eager" fetchpriority="high" />
            </a>
        </li>
        <!-- <li *ngIf="checkManager" (click)="contributionType('review')">
            <a routerLink="/review-activities" [routerLinkActive]="['active']" routerLinkActive
                #rlreview="routerLinkActive" matTooltip="Review Activity" matTooltipPosition="after"
                class="nav-link ml-1">
                <img class="icon_on" *ngIf="rlreview.isActive; else reviewInactive"
                    ngSrc='assets/images/ReviewWhite.svg' alt="reviewWhite" height="26" width="25" loading="eager"
                    fetchpriority="high" />
                <ng-template #reviewInactive>
                    <img class="icon_on" ngSrc='assets/images/Review.svg' alt="Review Grey" height="26" width="25"
                        loading="eager" fetchpriority="high">
                </ng-template>
                <img class="icon_off" ngSrc="assets/images/Reviewgreen.svg" alt="Review Green" height="26" width="25"
                    loading="eager" fetchpriority="high" />
            </a>
        </li> -->
        <li *ngIf="managerFlow || coChairFlow">
            <a [routerLinkActive]="['active']" routerLinkActive #rlaef="routerLinkActive" [routerLink]="routeHere"
                [matTooltip]="onHover" matTooltipPosition="after" class="nav-link">
                <img class="icon_on" *ngIf="rlaef.isActive; else rlaefInactive"
                    src='../../assets/images/Recommendation_White.svg' alt="ecdc White" />
                <ng-template #rlaefInactive>
                    <img class="icon_on" src='../../assets/images/Recommendation_Gray.svg' alt="ecdc Grey">
                </ng-template>
                <img class="icon_off" src="../../assets/images/Recommendation_Green.svg" alt="ecdc green" />
            </a>
        </li>
        <li *ngIf=" isHr">
            <a [routerLinkActive]="['active']" routerLinkActive #hrActive="routerLinkActive" routerLink="/HR-portal"
                matTooltip="HR Portal" matTooltipPosition="after" class="nav-link">
                <img class="icon_on" *ngIf="hrActive.isActive; else hrInActive" src='/assets/images/HR-white.svg'
                    alt="HR White" />
                <ng-template #hrInActive>
                    <img class="icon_on" src='/assets/images/HR_grey.svg' alt="HR Grey">
                </ng-template>
                <img class="icon_off" src="/assets/images/HR_green.svg" alt="ecdc green" />
            </a>
        </li>
    </ul>
</div>